@import 'theme.scss';
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@font-face {
  font-family: "Hurme";
  src: url("./assets/fonts/hurmegeometricsans4_light-webfont.ttf") format("truetype"),
}

h1, th, td, button.mat-button, p, mat-card-title, #title, .user, #price {
  font-family: 'Hurme' !important;
}

*:focus {
  outline: none !important;
  border: 0 !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.title {
  margin: 0 !important;
}

.refresh-button {
  margin-left: 5px !important;
  position: relative;
  top: 2px;
}
